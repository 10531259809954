import * as React from "react";
import Layout from "../components/Layout";
import { HtmlHead, Link } from "@cdv/jazz-web/components";
import { Typography, Box } from "@mui/material";
export const Head = () => (
  <HtmlHead title="Home page">
    <meta name="foo" content="bar" />
  </HtmlHead>
);

const NotFoundPage = () => {
  const primaryButton = {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
    a: {
      textDecoration: "none",
      color: "white",
      backgroundColor: "#e05206",
      padding: "8px 20px",
      borderRadius: "8px",
      transitionDuration: "0.3s",
      border: "1px solid #e05206",
    },
    "a:hover": {
      backgroundColor: "white",
      color: "#e05206",
      transitionDuration: "0.3s",
    },
  };
  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          height: "38vh",
        }}
      >
        <Typography
          variant="h1"
          sx={{ fontSize: "140px", fontWeight: "700", lineHeight: "70px" }}
        >
          404
        </Typography>
        <Typography sx={{ fontSize: "30px", textAlign: "center" }}>
          Omlouváme se, ale požadovaná stránka zde není.
        </Typography>
        <Box sx={primaryButton}>
          <Link to="/">Úvodní stránka</Link>
        </Box>
      </Box>
    </Layout>
  );
};
export default NotFoundPage;
